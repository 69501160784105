import { useAuth } from "@/hooks/auth";
import { ComponentType, ReactNode, useEffect } from "react";
import { CustomLoader } from "@/components";
import { useRouter } from "next/router";

interface WithAuthProps {
	[key: string]: unknown;
	children: ReactNode;
}

interface ConfigProps {
	[key: string]: {
		showDropdown?: boolean;
		showHeader?: boolean;
		isCollapsedSidebar?: boolean;
	};
}

const withAuth = <P extends WithAuthProps>(
	WrappedComponent: ComponentType<P>,
) => {
	const ComponentWithAuth = (props: P) => {
		const { isAuthenticated, loading } = useAuth();

		return (
			// Show a loading spinner while checking authentication
			<>
				{loading || !isAuthenticated ? (
					<CustomLoader open={true} bgOpacity={1} />
				) : null}

				{/* Render the protected component if the user is authenticated */}
				<WrappedComponent
					{...props}
					isAuthenticated={isAuthenticated}
					loading={loading}
				/>
			</>
		);
	};

	return ComponentWithAuth;
};

export default withAuth;
