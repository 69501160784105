import { Auth, Hub } from "aws-amplify";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	apiName,
	defaultRedirectingRouteBasedOnRole,
	getClinicCredentials,
	getClinicDomain,
	setTokensAndAuthenticate,
} from "../helpers/utils";
import {
	code,
	id,
	role,
	updateEcoSystemRole,
	updateRememberMe,
} from "../redux/user";
import { clearAllReduxStates, getCentralAuthUrl } from "../helpers";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import axios from "axios";
import { setSnackBarOptions } from "../redux/snackbar";
import { setAuthentication } from "@/redux/auth";
import { logout, logoutRouteHandler } from "@/helpers/auth";

export const useAuth = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const { rememberMe, loggedTime } = useSelector(
		(state: RootState) => state.user,
	);
	const currentLanguage = i18n.resolvedLanguage;
	const [loading, setLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const router = useRouter();

	useEffect(() => {
		setLoading(true);

		// Check if the user is already authenticated on initial load
		Auth.currentAuthenticatedUser({ bypassCache: true })
			.then((currentUser) => {
				setIsAuthenticated(true);
				dispatch(setAuthentication(true));
				setUser(currentUser);
				dispatch(id(currentUser.attributes.sub));
				if (currentUser.attributes.locale) {
					i18n.changeLanguage(currentUser.attributes.locale);
				}
				dispatch(
					role(
						currentUser.signInUserSession.accessToken.payload[
							"cognito:groups"
						].filter(
							(roles: string) => roles.split("_")[0] === "CNP",
						)[0],
					),
				);
			})
			.catch(() => {
				console.log("auth.tsx user un auth catch block");
				// clearAllReduxStates();
				setIsAuthenticated(false);
				dispatch(setAuthentication(false));
				setUser(null);
				// If not authenticated and no code available to fetch token, redirect to login
				// if (!router.query.code) {
				// 	logoutRouteHandler(router, dispatch);
				// }
				// if (
				// 	!allowedPaths.some((path) => router.pathname.includes(path))
				// ) {
				// 	router.push("/login");
				// }
			})
			.finally(() => {
				setLoading(false);
			});

		// Subscribe to authentication events
		const authListener = Hub.listen(
			"auth",
			async ({ payload: { event, data } }: any) => {
				switch (event) {
					case "signIn":
						//case "cognitoHostedUI":
						setIsAuthenticated(true);
						dispatch(setAuthentication(true));
						setUser(data);
						if (data.attributes.locale) {
							// i18n.changeLanguage(data.attributes.locale); // This changes users locale to the one saved in cognito
							await Auth.updateUserAttributes(data, {
								locale: currentLanguage,
							});
						}
						// const userRole =
						// 	data.signInUserSession.accessToken.payload[
						// 		"cognito:groups"
						// 	][0];
						dispatch(
							updateEcoSystemRole(
								data.signInUserSession.accessToken.payload[
									"cognito:groups"
								].map((roles: string) => roles.split("_")[0]),
							),
						);
						let userRole;
						const roles =
							data.signInUserSession.accessToken.payload[
								"cognito:groups"
							];
						for (const role of roles) {
							if (role.startsWith("CNP")) {
								userRole = role;
							}
						}
						// console.log(
						//   "role is",
						//   data.signInUserSession.accessToken.payload["cognito:groups"]
						// );
						if (userRole) {
							router.push(
								defaultRedirectingRouteBasedOnRole(userRole),
							);
						}
						break;
					case "signOut":
						clearAllReduxStates();
						setIsAuthenticated(false);
						dispatch(setAuthentication(false));
						setUser(null);
						logoutRouteHandler(router, dispatch);
						// router.reload(); // refresh the layout
						break;
					case "tokenRefresh":
						// * Commenting out this case for now, to check if this is causing the issue as after new token older values making user to unverified
						// 	if (
						// 		Number(loggedTime) + 8 * 60 * 60 * 1000 <
						// 			new Date().getTime() &&
						// 		rememberMe === "false" &&
						// 		!window.location.href.includes("localhost")
						// 	) {
						// 		rememberMeUnVerified();
						// 	}
						break;
					default:
						break;
				}
			},
		);

		// Clean up the listener when the component unmounts
		return () => {
			authListener();
		};
	}, []);

	// Remember me logout handler
	const rememberMeUnVerified = async () => {
		await logout();
		setIsAuthenticated(false);
		dispatch(setAuthentication(false));
		setUser(null);
		logoutRouteHandler(router, dispatch);
	};

	return {
		loading,
		isAuthenticated,
		user,
	};
};
